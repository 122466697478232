import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';

import { store } from '../../../App';
import { AppState } from '../../reducers';
import { SET_SELECTED_COLORWAY } from '../../actions/actionTypes';
import { ColorWheelSvg } from '../shared/colorWheelSvg';
import { BUILDER_URL_BASE, PDP_URL_BASE } from './gridwallConstants';
import {getGridImageEmbodeeUrl, getGridImageUrl, getInspirationImageUrls} from './gridwallImageUrlBuilder';
import {isConsumer} from '../../helpers/userProfileHelpers';
import {SustainabilityBadge} from '../shared/sustainabilityBadgeSVG';
import {isSustainableItemType} from '../../helpers/productTypeHelper';

interface OwnProps {
  item: AppState['gridwall']['products'][number];
  isLoggedIn: boolean;
}

interface DispatchProps {
  push: (url: string) => void;
}

type Props = OwnProps & DispatchProps;

interface State {
  currentImageColor: string;
  visibleThumbIndex: number;
  isActive: boolean;
  gotHovered: boolean;
  inspirations: {
    id: string;
    key: string;
  }[];
  currentInspiration: {
    id: string;
    key: string;
  };
  isValidProduct: boolean;
}

export const PRODUCT_TYPES = {
  BLANK_STOCK: 'BLANK_STOCK',
  PERSONALIZABLE: 'PERSONALIZABLE_STOCK',
  CUSTOMIZABLE: 'CUSTOMIZABLE'
};

export class GridCell extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.openPdp = this.openPdp.bind(this);
    this.state = {
      currentImageColor: props.item.colorNumber,
      visibleThumbIndex: 0,
      isActive: false,
      gotHovered: false,
      inspirations: [],
      currentInspiration: {id: "", key: ""},
      isValidProduct: true
    }
  }

  componentWillMount() {
    let curComponent = this;
    //For JBA (Custom, Digital) products, get inspirations for thumbnails
    const hasUniformProgram =
      this.props.item &&
      this.props.item.uniformProgram &&
      this.props.item.uniformProgram !== '';

    if(hasUniformProgram) {

      if(this.props.item.uniformProgram.toLowerCase().indexOf('stock') === -1) {
        getInspirationImageUrls(this.props.item.styleNumber).then(function(response) {
          //No id means we can use 'default' on url lookup
          let allInspirations = [];
          //If we have unique inspirations, we push them onto the array
          if(response && response.length > 0) {
            for (let i = 0; i < response.length; ++i) {
              let inspiration = response[i];
              allInspirations.push({id: inspiration.id, key: inspiration.key});
            }
          } else {
            //Initialize inspirations with the default inspiration
            allInspirations.push({id: "", key: curComponent.props.item.styleNumber});
          }
          //Finally, we initialize the state to reflect these changes
          //And select our initial (default) inspiration
          curComponent.setState({inspirations: allInspirations, currentInspiration: allInspirations[0]});
        });
      }

    } else {
      curComponent.setState({isValidProduct: false});
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ currentImageColor: nextProps.item.colorNumber });
  }

  openPdp() {
    let styleNumber = this.props.item.styleNumber;
    let colorNumber = this.state.currentImageColor;
    if (this.props.item.uniformProgram.toLowerCase().indexOf('stock') === -1) {
      const itemId = (this.state.currentInspiration.id) ? this.state.currentInspiration.id : this.props.item.styleNumber;
      window.location.href = window.location.protocol + '//' + window.location.host + BUILDER_URL_BASE + itemId;
    } else {
      store.dispatch({ type: SET_SELECTED_COLORWAY, styleNumber, colorNumber });
      this.props.push(`${PDP_URL_BASE}${styleNumber}-${colorNumber}`);
    }
  }

  onThumbHover = (color: string, e: unknown) => {
    this.setState({currentImageColor: color});
  }
  onThumbEmbodeeHover = (inspiration: State['currentInspiration'], e: unknown) => {
    this.setState({currentInspiration: inspiration});
  }
  onThumbToggleRight = () => {
    this.setState({visibleThumbIndex: this.state.visibleThumbIndex + 3});
  }
  onThumbToggleLeft = () => {
    this.setState({visibleThumbIndex: this.state.visibleThumbIndex - 3});
  }
  onMouseEntered = () => {
    if(!this.state.gotHovered) {
      this.setState({isActive: true, gotHovered: true});
    } else {
      this.setState({isActive: true});
    }
  };
  onMouseExited = () => {
    this.setState({isActive: false});
  }
  render() {

    if(this.state.isValidProduct) {

      const blankStyle = {
        paddingLeft: '5px'
      };

      let productType = PRODUCT_TYPES.BLANK_STOCK;

      if(this.props.item.uniformProgram.indexOf('Custom Stock') > -1) {
        productType = PRODUCT_TYPES.PERSONALIZABLE;
      } else if (this.props.item.uniformProgram.indexOf('Stock') === -1) {
        productType = PRODUCT_TYPES.CUSTOMIZABLE;
      }

      const colorString = this.props.item.numberOfColors > 1 ? 'Colors' : 'Color';
      const itemCount = productType === PRODUCT_TYPES.CUSTOMIZABLE ? this.state.inspirations.length : this.props.item.colors.length;
      const isSustainableItem = isSustainableItemType(this.props.item);

      return (
        <div className="col-xs-6 col-md-4 col-lg-3 grid-results-item-wrapper">
          <div className={`grid-cell-contents ${this.state.isActive ? "active":""}`} onMouseEnter={this.onMouseEntered} onMouseLeave={this.onMouseExited}>
            {isSustainableItem && <SustainabilityBadge />}
            <div className="grid-results-image"  onClick={this.openPdp}>

              {/* Main image - Stock */}
              {productType !== PRODUCT_TYPES.CUSTOMIZABLE && this.props.item.colors.map( (color, i) => {
                return <img key={color} className={this.state.currentImageColor !== color ? "hidden" : ""}
                            alt={this.props.item.styleNumber}
                            src={getGridImageUrl(window.location, this.props.item.styleNumber, color, "grid", "", "", productType)} />
              })}

              {/* Main image - Embodee */}
              {productType === PRODUCT_TYPES.CUSTOMIZABLE && this.state.inspirations.map( (inspiration, i) => {
                return <img key={inspiration.id + "_embodee"} className={this.state.currentInspiration.id !== inspiration.id ? "hidden" : ""}
                            alt={this.props.item.styleNumber}
                            src={getGridImageEmbodeeUrl(this.state.currentInspiration, 'grid')} />
              })}
            </div>

            {/* Bar under main image */}
            <div className="grid-cell-color-options">
              {/* Left arrow*/}
              {itemCount > 3 && this.state.visibleThumbIndex !== 0 &&
                <div className="grid-cell-thumb-toggle left" onClick={this.onThumbToggleLeft}><i className="g72-arrow-thin-left" /></div>
              }

              {/* Stock image hover thumbs*/}
              {this.state.gotHovered && productType !== PRODUCT_TYPES.CUSTOMIZABLE && this.props.item.colors.map( (color, i) => {
                return <img key={color} className={"grid-cell-color-option " + (i >= this.state.visibleThumbIndex && i < (this.state.visibleThumbIndex + 3) ? "showing" : '')}
                            onMouseOver={(e) => this.onThumbHover(color, e)}
                            alt={this.props.item.styleNumber}
                            onClick={this.openPdp}
                            src={getGridImageUrl(window.location, this.props.item.styleNumber, color, "thumb", "", "", productType)} />
              })}

              {/* Embodee image hover thumbs*/}
              {this.state.gotHovered && productType === PRODUCT_TYPES.CUSTOMIZABLE && this.state.inspirations.map( (inspiration, i) => {
                return <img key={inspiration.id} className={"grid-cell-color-option " + (i >= this.state.visibleThumbIndex && i < (this.state.visibleThumbIndex + 3) ? "showing" : '')}
                            onMouseOver={(e) => this.onThumbEmbodeeHover(inspiration, e)}
                            alt={this.props.item.styleNumber}
                            onClick={this.openPdp}
                            data-inspiration={inspiration.id}
                            src={getGridImageEmbodeeUrl(inspiration, 'thumb')} />
              })}

              {/* Right Arrow*/}
              {itemCount > 3 && this.state.visibleThumbIndex < (itemCount - 3) &&
                <div className="grid-cell-thumb-toggle right" onClick={this.onThumbToggleRight}><i className="g72-arrow-thin-right" /></div>
              }

            </div>
            <div className="grid-cell-info" onClick={this.openPdp}>
              <div className="grid-cell-style-details">
                <span className="grid-cell-style-number">{this.props.item.styleNumber}</span>
                {productType === PRODUCT_TYPES.CUSTOMIZABLE || productType === PRODUCT_TYPES.PERSONALIZABLE ?
                  <span className="grid-cell-customize-it">
                    <ColorWheelSvg className="inline-color-wheel" height={12} width={12} />
                    {productType === PRODUCT_TYPES.PERSONALIZABLE ? "Personalize" : (!this.props.isLoggedIn || isConsumer()) ? "Contact a Dealer" : "Customize" }
                  </span>
                  : <span className="grid-cell-color-count">{this.props.item.numberOfColors} {colorString}</span>
                }
              </div>

              <div className="grid-cell-product-name">{this.props.item.displayName}</div>

              {isSustainableItem && <div className="grid-cell-product-sustainable">Sustainable Materials</div>}

              <div className="grid-cell-product-price">
                <span>${this.props.item.listPrice}</span>
                {productType === PRODUCT_TYPES.CUSTOMIZABLE ?
                  <span style={blankStyle}>Blank</span> : ''
                }
              </div>

              <div className="grid-cell-shipby-date">
                {productType === PRODUCT_TYPES.CUSTOMIZABLE &&
                  <span>{this.props.item.shipDate ? 'Estimated Arrival Date ' + moment(this.props.item.shipDate).utcOffset(0).format("M/DD/YY") : 'NOT ORDERABLE'}</span>
                }
              </div>

            </div>
          </div>
        </div>
      );

    } else {
      return null;
    }

  }
}

export default connect(null, {push})(GridCell);
