import {fetchRequest, urlNtsServices} from "./index";
import {AUTH_HEADER_PREFIX, LS_ACCESS_TOKEN} from "../../helpers/constants";

export interface SearchState {
    state: string;
    organization: string;
    school: string;
    type?: string;
}

export const SchoolService = {
    searchSchool: async (search:SearchState) => {
        const url = `${urlNtsServices}/school/search/v1?state=${search.state}&organization=${search.organization}&schoolName=${search.school}`;
        const request = new Request(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
            }
        });
        return fetchRequest(request);
    },
    updateSchool: async (schools:any) => {
        const request = new Request(`${urlNtsServices}/school/update/v1`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(schools)
        });
        return fetchRequest(request);
    }
}