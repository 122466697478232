import * as React from 'react';

import Select from 'react-select';

import {SelectOption} from '../../../helpers/formHelpers';
import {SearchState} from '../../../api/services/schoolService';
import {alphaNumericWithSpaceAndDashSanitizer} from "../../../helpers/textValidationHelpers";

interface State {
    inputs: {
        state: SelectOption,
        org: SelectOption,
        school: string
    },
    errors: {
        state: boolean,
        org: boolean,
        school: boolean
    }
}

interface Props {
    performSearch: (state:SearchState) => void,
    stateList: Array<SelectOption>,
    orgList: Array<SelectOption>
}

export const SchoolSearchBar: React.FC<Props> = ({ performSearch, stateList, orgList }) => {

    const initialState: State = {
        inputs: {
            state: {value: '', label: ''},
            org: {value: '', label: ''},
            school: ''
        },
        errors: {
            state: false,
            org: false,
            school: false
        }
    }

    const [state, setState] = React.useState(initialState);

    const handleStateChange = (option:any) => {
        setState({...state, inputs: {...state.inputs, state: {...option} }, errors: {...state.errors, state: false}});
    }

    const handleOrgChange = (option:any) => {
        setState({...state, inputs: {...state.inputs, org: {...option} }, errors: {...state.errors, org: false}});
    }

    const handleSchoolChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const sanitizedInput = alphaNumericWithSpaceAndDashSanitizer(e.target.value);
        setState({...state, inputs: {...state.inputs, school: sanitizedInput}, errors: {...state.errors, school: e.target.value === ''} });
    }

    const handleSearchRequest = () => {
        if(state.inputs.state.value === '' || state.inputs.org.value === '' || state.inputs.school === '') {
            setState({...state, errors: {state: state.inputs.state.value === '', org: state.inputs.org.value === '', school: state.inputs.school === ''}});
            return;
        }
        performSearch({
            state:state.inputs.state.value,
            organization:state.inputs.org.value,
            school:state.inputs.school
        });
    }

    return (
        <div className="nts-school-search-bar">
            <h4>Search for a school</h4>
            <div className="nts-school-search-bar-inputs">
                <label htmlFor="school-search-state">
                    <span>State:</span>
                    <Select
                        value={state.inputs.state.value}
                        options={stateList}
                        onChange={handleStateChange}
                        placeholder="Select state"
                        clearable={false}
                        className="nts-school-search-input"
                        name="school-search-state"
                    />
                    {state.errors.state && <p className="error">Please select a state</p>}
                </label>
                <label htmlFor="school-search-org">
                    <span>Org Type:</span>
                    <Select
                        value={state.inputs.org.value}
                        options={orgList}
                        onChange={handleOrgChange}
                        placeholder="Select org type"
                        clearable={false}
                        className="nts-school-search-input"
                        name="school-search-org"
                    />
                    {state.errors.org && <p className="error">Please select an org type</p>}
                </label>
                <label htmlFor="school-search-name">
                    <span>School Name:</span>
                    <input
                        type="text"
                        value={state.inputs.school}
                        onChange={handleSchoolChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') handleSearchRequest()
                        }}
                        placeholder="Enter school name"
                        className="nts-school-search-input"
                        name="school-search-name"
                    />
                    {state.errors.school && <p className="error">Please enter a school name</p>}
                </label>
                <div>
                    <button className="btn btn-primary" onClick={handleSearchRequest}>Search</button>
                </div>
            </div>
        </div>
    );

};