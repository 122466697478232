import {SelectOption} from "../../../helpers/formHelpers";

export interface School {
    name: string;
    tier: number;
    code: string;
    address: string;
    city: string;
    hasUpdate?: boolean;
}

export const ORG_TYPE_LIST_SCHOOL = "SCHOOL_ONLY";
export const ORG_TYPE_LIST_ALL = "ALL";


// Select menu options for searching/selecting organization types
export const getOrganizationTypes = (type:String):SelectOption[] => {
    switch (type) {
        case ORG_TYPE_LIST_SCHOOL:
            return [
                { value: "HIGH_SCHOOL", label: "High School" },
                { value: "COLLEGE", label: "University/College" }
            ];
        case ORG_TYPE_LIST_ALL:
            return [
                { value: "HIGH_SCHOOL", label: "High School" },
                { value: "COLLEGE", label: "University/College" },
                { value: "EVENT", label: "Event" },
                { value: "CLUB", label: "Club" },
                { value: "RECREATIONAL", label: "Recreational" }
            ];
        default:
            return [
                { value: "HIGH_SCHOOL", label: "High School" },
                { value: "COLLEGE", label: "University/College" },
                { value: "EVENT", label: "Event" },
                { value: "CLUB", label: "Club" },
                { value: "RECREATIONAL", label: "Recreational" }
            ];
    }
}

export const getTierTypes = ():SelectOption[] => {
    return [
        { value: "0", label: "None" },
        { value: "1", label: "1 - Sports Marketing" },
        { value: "2", label: "2 - Other Colleges" },
        { value: "3", label: "3 - Elite High Schools" },
        { value: "4", label: "4 - Team Dealer" }
    ]
}

export const getSchoolUpdatePayload = (schools:School[]):School[] => {
    return schools.filter(school => school.hasUpdate);
}
