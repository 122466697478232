import React, {Suspense} from 'react';
import {ConnectedRouter} from 'connected-react-router';
import {
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import {history} from './js/store';

// Components
import Navbar from './js/components/header/navbar';
import Footer from './js/components/footer/footer';
import AnnouncementBanner from './js/components/banner/AnnouncementBanner';
import {MessageModal} from './js/components/messageModal/MessageModal';
import {CouponLookupModal} from './js/components/coupons/lookup/modal';
import {Toast} from './js/components/toast';
import {NewRelic} from './js/components/newRelic/NewRelic';
import {ProtectedRoute} from "./js/components/protectedRoute/ProtectedRoute";
import {FullPageSpinner} from "./js/components/shared/spinners/FullPageSpinner";
import {CartSelectorModal} from "./js/components/cart/cartSelector";
import {FillinHelperModal} from './js/components/header/FillinHelperModal';
import {RoleProtectedRoute} from "./js/components/protectedRoute/RoleProtectedRoute";

// Pages
import {MainLandingPage} from './js/components/landingPages/mainLandingPage';
import {ScheelsLandingPage} from './js/components/retailer/scheels/scheelsLandingPage';
import {OrdersPage} from './js/components/orders/ordersPage';
import {OrderHistoryPage} from './js/components/history/orders';
import {QuoteHistoryPage} from './js/components/history/quotes';
import {CatalogPage} from './js/components/catalogPage/CatalogPage';
import {FindDealerPage} from './js/components/findDealer/findDealer';
import {NotFoundPage} from './js/components/notFound';
import {ArtworkTermsPage} from './js/components/terms/artworkTerms';
import {CovidStatementPage} from './js/components/covid/covidStatementPage';
import {ColorOptionsPage} from './js/components/help/colors/colorOptionsPage';
import {ConfirmationPage} from './js/components/checkout/confirmation/confimation';
import {FillInOrderPage} from './js/components/fillinOrder/fillInOrder';
import {LockerPage} from './js/components/locker/lockerPage';
import LoadingPage from "./js/components/shared/spinners/LoadingPage";
import {CUSTOM_FONTS_PAGE_ROLES, CustomFontsPage} from "./js/components/customFonts/CustomFontsPage";
import {CUSTOM_COLORS_PAGE_ROLES, CustomColorsPage} from "./js/components/customColors/CustomColorsPage";
import SalesToolsPage from './js/components/admin/salesTools/SalesToolsPage';
import {ADMIN_PAGE_ROLES, ADMIN_DEALER_PAGE_ROLES, DEALER_SHARE_ROLES, salesToolsLinkAuths} from "./js/helpers/navbarHelpers";
import {AdminAnalyticsPage} from "./js/components/admin/analytics/AdminAnalyticsPage";
import {PromoLandingPage} from "./js/components/gridwall/promo/PromoPage";
import {AdminReportingPage} from "./js/components/admin/reporting/AdminReportingPage";
import {OrderDeadlinesPage} from "./js/components/orderDeadlines/OrderDeadlinesPage";
import {DealerSharePage} from "./js/components/dealerShare/DealerSharePage";
import { AdminFactoryPage } from './js/components/admin/factory/AdminFactoryPage';
import { AdminFactoryShipGroupPage } from './js/components/admin/factory-ship-group/AdminFactoryPage';
import { FactoryXlsUploadPage } from './js/components/admin/factoryXlsUpload/FactoryXlsUploadPage';
import {LoginCallback} from "./auth/components/loginCallback";
import {LogoutCallback} from "./auth/components/logoutCallback";

import {SchoolAdminPage} from "./js/components/admin/schoolAdmin/SchoolAdminPage";

// Large Pages - Lazy load pages with large memory footprints (creates separate JS bundle)
const FaqPage = React.lazy(() => import("./js/components/help/faq/faqPage"));
const QuoteSubmitPage = React.lazy(() => import("./js/components/checkout/quote/quote"));
const Checkout = React.lazy(() => import("./js/components/checkout/checkout"));
const CouponCheckoutPage = React.lazy(() => import("./js/components/checkout/coupon/couponCheckout"));
const GridwallPage = React.lazy(() => import("./js/components/gridwall/gridwallPage"));
const PdpPage = React.lazy(() => import("./js/components/pdp/pdpPage"));
const ProfilePage = React.lazy(() => import("./js/components/profile/profilePage"));
const StyleSearchPage = React.lazy(() => import("./js/components/iqTool/StyleSearchPage"));

export const AppRoutes: React.FC = () => {
    return (
        <ConnectedRouter history={history}>
            <>
            <FullPageSpinner />
            <MessageModal />
            {/* TODO: Initialize <CouponLookupModal> more efficiently, import on necessary pages only (calls Coupon service repeatedly) */}
            <CouponLookupModal />
            <Toast />
            <CartSelectorModal />
            <FillinHelperModal />
            <Navbar />
            <AnnouncementBanner />
            <Suspense fallback={<LoadingPage />}>
                <Switch>
                    <ProtectedRoute path="/en/en_US/nts/orders" component={OrdersPage} />
                    <Route exact path="/en/en_US/nts/email/orders" component={MainLandingPage} />
                    <ProtectedRoute path="/en/en_US/nts/history/orders" component={OrderHistoryPage} />
                    <ProtectedRoute path="/en/en_US/nts/history/quotes" component={QuoteHistoryPage} />
                    <Route exact path="/en/en_US/nts/catalogs" component={CatalogPage} />
                    <Route exact path="/en/en_US/nts/find_dealer" component={FindDealerPage} />
                    <ProtectedRoute path="/en/en_US/nts/user/profile" component={ProfilePage} />
                    <ProtectedRoute path="/en/en_US/nts/checkout/coupon/:id" component={CouponCheckoutPage} />
                    <ProtectedRoute path="/en/en_US/nts/checkout/order/:id" component={Checkout} />
                    <ProtectedRoute path="/en/en_US/nts/checkout/quote/:id" component={QuoteSubmitPage} />
                    <ProtectedRoute path="/en/en_US/nts/locker" component={LockerPage} />
                    <RoleProtectedRoute path="/en/en_US/nts/admin/custom-fonts" component={CustomFontsPage} roles={CUSTOM_FONTS_PAGE_ROLES} />
                    <RoleProtectedRoute path="/en/en_US/nts/admin/custom-colors" component={CustomColorsPage} roles={CUSTOM_COLORS_PAGE_ROLES} />
                    <RoleProtectedRoute path="/en/en_US/nts/documents" component={SalesToolsPage} roles={salesToolsLinkAuths}/>
                    <RoleProtectedRoute path="/en/en_US/nts/admin/analytics" component={AdminAnalyticsPage} roles={ADMIN_PAGE_ROLES} />
                    <RoleProtectedRoute path="/en/en_US/nts/admin/reporting" component={AdminReportingPage} roles={ADMIN_PAGE_ROLES} />
                    <RoleProtectedRoute path="/en/en_US/nts/factory/xls-upload" component={FactoryXlsUploadPage} roles={ADMIN_PAGE_ROLES} />
                    <RoleProtectedRoute path="/en/en_US/nts/view_deadlines" component={OrderDeadlinesPage} roles={ADMIN_DEALER_PAGE_ROLES}/>
                    <RoleProtectedRoute path="/en/en_US/nts/dealer_share" component={DealerSharePage} roles={DEALER_SHARE_ROLES}/>
                    <Route path="/en/en_US/nts/confirmation/coupon/:id" component={ConfirmationPage} />
                    <Route path="/en/en_US/nts/confirmation/order/:id" component={ConfirmationPage} />
                    <Route path="/en/en_US/nts/confirmation/quote/:id" component={ConfirmationPage} />
                    <Route path="/en/en_US/nts/gridwall/:gender/:sport/:routeKey" component={GridwallPage} />
                    <Route path="/en/en_US/nts/sport/:gender/:sport" component={GridwallPage}/>
                    <Route path="/en/en_US/nts/promo/:gender/:sport" component={PromoLandingPage}/>
                    <Route path="/en/en_US/nts/pdp/:type/:id" component={PdpPage} />
                    <Route path="/en/en_US/nts/fillin" component={FillInOrderPage} />
                    <Route path="/en/en_US/nts/404" component={NotFoundPage} />
                    <Route path="/en/en_US/nts/terms/artwork" component={ArtworkTermsPage} />
                    <Route path="/en/en_US/nts/help/colors" component={ColorOptionsPage} />
                    <Route exact path="/en/en_US/nts/help/faq" component={FaqPage} />
                    <Route path="/en/en_US/nts/help/faq/:category/:topic" component={FaqPage} />
                    <Route path="/en/en_US/nts/help/covid" component={CovidStatementPage} />
                    <Route path="/en/en_US/nts/scheels" component={ScheelsLandingPage} />
                    <ProtectedRoute path="/en/en_US/nts/style_search" component={StyleSearchPage} />
                    <RoleProtectedRoute path="/en/en_US/nts/admin/factory/ship/group/:id" component={AdminFactoryShipGroupPage} roles={ADMIN_PAGE_ROLES}/>
                    <RoleProtectedRoute path="/en/en_US/nts/admin/factory" component={AdminFactoryPage} roles={ADMIN_PAGE_ROLES}/>
                    <RoleProtectedRoute path="/en/en_US/nts/admin/school" component={SchoolAdminPage} roles={ADMIN_PAGE_ROLES}/>

                    {/* Auth Handling Routes */}
                    <Route exact path={["/en/en_US/nts/oidc/login","/oidc/login"]} component={LoginCallback} />
                    <Route exact path={["/en/en_US/nts/oidc/logout","/oidc/logout"]} component={LogoutCallback} />

                    {/* Default Route & Catch All */}
                    <Route exact path="/en/en_US/nts" component={MainLandingPage} />
                    <Route exact path={["*", "/", "/en", "/en/en_US"]}>
                        <Redirect to="/en/en_US/nts" />
                    </Route>

                </Switch>
            </Suspense>
            <NewRelic />
            <Footer />
            </>
        </ConnectedRouter>
    );
};
