import React from 'react';
import ReactTable from 'react-table';
import {connect} from 'react-redux';

import {store} from "../../../App";
import {getPastOrders} from "../../actions/orderActions";
import {navigateToShoppingHome} from "../../helpers/navbarHelpers";
import {getPastOrderTableConfig} from "./ordersConfig";

/**
 * Provides page for viewing past (submitted) orders
 */
export class OrderHistoryClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ordersLoaded: false,
      searchValue: ''
    };
  }

  componentWillMount() {
    this.loadPastOrders();
  }

  loadPastOrders = () => {
    store.dispatch(getPastOrders(this.state.searchValue)).then(() => {
      this.setState({
        ordersLoaded: true
      })
    }).catch(e => {
      console.error('Error loading past orders', e);
    });
  };

  defaultFilterMethod(filter, row) {
    return String(row[filter.id]).toUpperCase().includes(filter.value.toUpperCase())
  }

  render() {
    return (
      <div className="container nts-past-orders-container">

        <div className="row nts-past-orders-header">
          <div className="col-xs-5 col-sm-10">
            <h1>ORDERS</h1>
          </div>
          <div className="col-xs-7 col-sm-2">
            <button
              className="btn btn-default past-orders-cont-shop"
              onClick={navigateToShoppingHome}
            >
              <img alt="" src="/img/carrot.svg" className="past-order-btn-caret"/>
              <span>CONTINUE SHOPPING</span>
            </button>
          </div>
        </div>

        <div className="row nts-table-wrapper">
          <div className="col-xs-12" style={{overflow:"auto"}}>
            <ReactTable
                className="nts-table"
                data={this.props.ordersState.pastOrderData.orders}
                filterable
                defaultFilterMethod={this.defaultFilterMethod}
                noDataText="NO ORDERS FOUND"
                loading={!this.state.ordersLoaded}
                columns={getPastOrderTableConfig()}
                minRows={0}
                resizable={true}
                style={{height:"auto"}}
                getTdProps={(state, rowInfo, column) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      
                      if (column.expander) {
                        if (handleOriginal) {
                          handleOriginal()
                        }
                      } else {
                        // TODO PMENTS-2247: uncomment conditional block when ready to release
                        if(column.id !== "shippingInfo") {
                          let pastOrderParam = rowInfo.original.isSAP === 'true' ? "isSapPastOrderCart=true" : "isJbaPastOrderCart=true";
                          let id = rowInfo.original.isSAP === 'true' ? rowInfo.original.sapCartId : rowInfo.original.id;
                          let cartIdParam = rowInfo.original.isSAP === 'true' ? "sapCartId" : "cartId";
                          window.location = window.location.protocol + '//' + window.location.host
                              + '/en/en_US/p/order,detail?' + cartIdParam
                              + '=' + id
                              + "&" + pastOrderParam
                              + "&cartId=" + rowInfo.original.parentCartId;
                        }
                      }
                    }
                  }
                }}
            />

          </div>
        </div>

      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    ordersState: state.ordersState
  }
};

export const OrderHistoryPage = connect(
    mapStateToProps
)(OrderHistoryClass);