import * as React from 'react';

import {SchoolSearchBar} from './SchoolSearchBar';
import {SchoolSearchResults} from './SchoolSearchResults';

import {SchoolService, SearchState} from '../../../api/services/schoolService';
import {getOrganizationTypes, getSchoolUpdatePayload, ORG_TYPE_LIST_SCHOOL, School} from './schoolHelpers';
import {store} from '../../../../App';
import {showToast} from '../../../actions/toastActions';
import {getStateList} from "../../../helpers/stateList";
import {SelectOption} from "../../../helpers/formHelpers";

export interface ResultState {
    original: School[],
    editable: School[],
    hasEdits: boolean
}

interface State {
    results: ResultState,
    errors: {
        search: string,
        update: string
    }
}

export const SchoolAdminPage: React.FC = () => {

    const initialState:State = {
        results: {
            original: [],
            editable: [],
            hasEdits: false
        },
        errors: {
            search: '',
            update: ''
        }
    }

    const [state, setState] = React.useState(initialState);

    const stateList: Array<SelectOption> = getStateList();
    const orgList: Array<SelectOption> = getOrganizationTypes(ORG_TYPE_LIST_SCHOOL);

    //--- Perform service call to search for schools
    const handleSearch = async (search:SearchState) => {
        await SchoolService.searchSchool(search)
            .then(response => {
                if(response.error) {
                    console.log('School search failed: ', response.message);
                    setState({...state, errors: { ...state.errors, search: response.message }});
                } else {
                    let schools = response.data;
                    schools = schools.map((school:School) => ({ ...school, hasUpdate: false }));
                    setState({
                        ...state,
                        errors: { search: schools.length == 0 ? 'No results found. Please update search criteria.' : '', update: ''},
                        results: { original: schools, editable: JSON.parse(JSON.stringify(schools)), hasEdits: false }
                    });
                }
            }).catch(error => {
                console.log('School search failed: ', error);
                setState({...state, errors: {...state.errors, search: 'Failed to search schools'} });
            });
    }


    //--- Perform service call to update school tiers
    const handleUpdate = async () => {
        const schools = getSchoolUpdatePayload(state.results.editable);
        await SchoolService.updateSchool(schools)
            .then(response => {
                if(response.error) {
                    console.log('School update failed: ', response.error);
                    setState({...state, errors: {...state.errors, update: response.message}});
                } else {
                    store.dispatch(showToast('SCHOOL UPDATE SUCCESSFUL'));
                    const resetSchools = state.results.editable.map((school:School) => ({ ...school, hasUpdate: false }) );
                    setState({...state, results: {original: resetSchools, editable: resetSchools, hasEdits: false}});
                }
            })
            .catch(error => {
                console.log('School update failed: ', error);
                setState({...state, errors: {...state.errors, update: 'Failed to update school tiers'}});
            });
    }

    //--- Update state for school results from <SchoolSearchResults />
    const updateResultState = (resultState:ResultState) => {
        setState({...state, results: {...resultState}});
    }

    return (
        <div className="nts-admin-page" id="nts-school-admin-page">

            <h1 className="title">School Admin</h1>

            <SchoolSearchBar performSearch={handleSearch} stateList={stateList} orgList={orgList} />
            {state.errors.search && <div className="error search-error">{state.errors.search}</div>}

            {state.results.editable && state.results.editable.length > 0 &&
                <>
                    <SchoolSearchResults resultState={state.results} updateResultState={updateResultState} submitUpdate={handleUpdate} />
                    {state.errors.update && <div className="error update-error">{state.errors.update}</div>}
                </>
            }

        </div>
    );

};