import React from 'react';

import {
  hasRolePermission,
  isAdminRole,
  isCustomerServiceRole,
  isEliteDealerRole
} from '../../../helpers/userProfileHelpers';

export const AdminOptions = (props) => {
  const hasEliteAdminOptions = isEliteDealerRole() || isCustomerServiceRole() || isAdminRole();
  return (
    <div>
      {hasRolePermission('ROLE_USER_SEARCH') &&
        <a className="nts-simple-menu-item mobile-option" href={"/en/en_US/p/admin,user?_t="+((new Date()).getTime() / 1000)} onClick={props.clickHandler}>Users</a>
      }
      {hasRolePermission('ROLE_DCART_SHARE') &&
        <a className="nts-simple-menu-item mobile-option" href={"/en/en_US/p/dealer,share?_t="+((new Date()).getTime() / 1000)} onClick={props.clickHandler}>Share</a>
      }
      {hasRolePermission('ROLE_DEALER_SEARCH') &&
        <a className="nts-simple-menu-item mobile-option" href={"/en/en_US/p/dealer,list?_t="+((new Date()).getTime() / 1000)} onClick={props.clickHandler}>Dealers</a>
      }
      {hasRolePermission('ROLE_DEALER_SEARCH') &&
        <a className="nts-simple-menu-item mobile-option" href={"/en/en_US/p/dealer_loc,list?_t="+((new Date()).getTime() / 1000)} onClick={props.clickHandler}>Contact List</a>
      }
      {hasRolePermission('ROLE_DEALER_EDIT') &&
        <a className="nts-simple-menu-item mobile-option" href={"/en/en_US/p/dealer_loc,rank?_t="+((new Date()).getTime() / 1000)} onClick={props.clickHandler}>State Rankings</a>
      }
      {isAdminRole() &&
      <>
        <a className="nts-simple-menu-item mobile-option" href={"/en/en_US/nts/admin/factory"} onClick={props.clickHandler}>Factory Setup</a>
      </>
      }
      {hasRolePermission('ROLE_ADMIN_REPORTING') &&
          <a className="nts-simple-menu-item mobile-option" href={"/en/en_US/nts/admin/reporting"} onClick={props.clickHandler}>Reporting</a>
      }
      {hasRolePermission('ROLE_ADMIN_ANALYTICS') &&
        <a className="nts-simple-menu-item mobile-option" href={"/en/en_US/nts/admin/analytics"} onClick={props.clickHandler}>Analytics</a>
      }
      {hasEliteAdminOptions &&
        <a className="nts-simple-menu-item mobile-option" href={"/en/en_US/nts/admin/custom-fonts"} onClick={props.clickHandler}>Custom Fonts</a>
      }
      {hasEliteAdminOptions &&
        <a className="nts-simple-menu-item mobile-option" href={"/en/en_US/nts/admin/custom-colors"} onClick={props.clickHandler}>Custom Colors</a>
      }
      {isAdminRole() &&
          <a className="nts-simple-menu-item mobile-option" href={"/en/en_US/nts/admin/school"} onClick={props.clickHandler}>Schools</a>
      }
    </div>
  );
};
