export function validateInteger(value) {
  if (value !== '') {
    return /^-{0,1}\d+$/.test(value);
  } else {
    return true;
  }
}

export function textValidator(value) {
    if (value !== '') {
        return  /^[A-Za-z0-9'\s-.,/#]+$/.test(value);
    } else {
        return true;
    }
}

// Allows A-Z, a-z, 0-9, period, space, dash, ampersand, apostrophe
export function organizationEntrySanitizer(value) {
    if (typeof value === 'string' && value !== '') {
        return value.replace(/([^A-Za-z0-9-.&'\s])+/g, "");
    } else {
        return value;
    }
}

export function alphaNumericWithDashSanitizer(value) {
  if (typeof value === 'string' && value !== '') {
    return value.replace(/([^0-9A-Za-z\-])+/g, "");
  } else {
    return value;
  }
}

// Allows alphanumeric characters, spaces, and dashes
export function alphaNumericWithSpaceAndDashSanitizer(value) {
    if (typeof value === 'string' && value !== '') {
        return value.replace(/([^0-9A-Za-z\- ])+/g, "");
    } else {
        return value;
    }
}

export function phoneNumberValidator(value) {
    if (value !== '') {
        return /^[0-9]+$/.test(value);
    } else {
        return true;
    }
}

export function emailValidator(value) {
  if (value !== '') {
    return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(value);
  } else {
    return false;
  }
}

export function teamNameValidator(value) {
  if (value !== '') {
    return /^[A-Za-z0-9'\s-.,/]*$/.test(value);
  } else {
    return true;
  }
}

export function addressNameValidator(value) {
  if (value !== '') {
    return /^[A-Za-z0-9'\s-.,/#]*$/.test(value);
  } else {
    return true;
  }
}

export function addressValidator(value) {
  if (value !== '') {
    return /^[A-Za-z0-9'\s-.,#]*$/.test(value);
  } else {
    return true;
  }
}

export function zipValidator(value) {
  if (value !== '') {
    return /^[A-Za-z0-9\s-]*$/.test(value);
  } else {
    return true;
  }
}
//
// export function inputHasValue(value) {
//   if (!this.state.value)
//     return 'error';
//   else return null;
// }

/**
 * Removes spaces from string (any position)
 * @param value
 * @returns {*}
 */
export function removeSpaces(value) {
  return (value) ? value.replace(/\s/g, '') : '';
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}