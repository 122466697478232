import * as React from 'react';
import Select from 'react-select';

import {getTierTypes, School} from './schoolHelpers';
import {ResultState} from "./SchoolAdminPage";
import ReactTable from "react-table";

interface Props {
    resultState: {
        original: School[],
        editable: School[],
        hasEdits: boolean
    }
    updateResultState: (state:ResultState) => void
    submitUpdate: () => void
}

export const SchoolSearchResults: React.FC<Props> = ({ resultState, updateResultState, submitUpdate }) => {

    const tierTypes = getTierTypes();

    const handleTierChange = (selected:any, index:number) => {
        const newResults = JSON.parse(JSON.stringify(resultState.editable));
        newResults[index].tier = Number(selected.value);
        const dirtyCheck = newResults[index].tier == resultState.original[index].tier;
        newResults[index].hasUpdate = !dirtyCheck;
        const hasEdits = JSON.stringify(newResults) != JSON.stringify(resultState.original);
        updateResultState({...resultState, editable: newResults, hasEdits: hasEdits});
    }

    const handleCancelUpdates = () => {
        updateResultState({...resultState, editable: JSON.parse(JSON.stringify(resultState.original)), hasEdits: false});
    }

    // Style rows based on update status
    const getTrProps = (state: any, rowInfo: any, instance: any) => {
        if (rowInfo) {
            return {
                style: {
                    backgroundColor: rowInfo
                        ? rowInfo.original.hasUpdate
                            ? "#99CC00"
                            : "white"
                        : "white"
                }
            };
        }
        return {};
    }

    return (
        <div className='nts-school-search-results'>
            <h4>Search Results ( {resultState.editable.length} )</h4>
            <ReactTable
                className="table nts-table"
                data={resultState.editable}
                noDataText={"No schools found"}
                loading={false}
                minRows={0}
                style={{height:"auto"}}
                pageSize={20}
                showPageSizeOptions={false}
                getTrProps={getTrProps}
                columns={[
                    {
                        Header: 'School Code',
                        accessor: 'code',
                        show: false
                    },
                    {
                        Header: "School Name",
                        accessor: "name"
                    },
                    {
                        Header: "Address",
                        accessor: "address"
                    },
                    {
                        Header: "City",
                        accessor: "city"
                    },
                    {
                        Header: "Tier",
                        accessor: "tier",
                        className: "tier_col",
                        Cell: (rowInfo:any) => {
                            return (
                                <Select
                                    value={rowInfo.original.tier}
                                    options={tierTypes}
                                    onChange={(selected) => handleTierChange(selected, rowInfo.index)}
                                    placeholder='Select tier'
                                    clearable={false}
                                />
                            );
                        }
                    }
                ]}
            />
            <div className='button-bar'>
                <button className='btn btn-default' onClick={handleCancelUpdates}>Cancel</button>
                <button className='btn btn-primary' disabled={!resultState.hasEdits} onClick={submitUpdate}>Submit</button>
            </div>
        </div>
    );

};